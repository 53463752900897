import { createCampaignControllerTriggers } from "./factory/campaignControllerTriggerFactory";
export class CampaignStep {
    constructor(response, context) {
        this.type = response.type;
        this.id = response.id;
        this.context = context;
        this.enabled = response.enabled;
        this.component_ids = response.component_ids;
        this.created_date = response.created_date;
        this.updated_date = response.updated_date;
        this.triggers = createCampaignControllerTriggers(response.triggers, {
            campaignId: context.campaignId,
            stepId: response.id,
        });
    }
    getTrigger(triggerId) {
        return this.triggers.find((trigger) => trigger.trigger_id === triggerId);
    }
}
