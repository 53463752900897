import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import Uri from "jsuri";
import Session from "Session";
import featureFlagService from "src/shared/feature-flag-service";
import notificationService from "src/shared/notification/notification-service";
import chatBot, {
    initChatBotCloseButton,
} from "src/shared/third-party-integrations/chat-bot";
import pendo from "src/shared/third-party-integrations/pendo";
import extoleInvite from "./third-party-integrations/extole-invite";
import faro from "./third-party-integrations/faro";
import environment from "./environment";
import "../../vendor/foundation/css/foundation.css";
import "../../vendor/fontawesome-v6/css/all.css";
import "../../vendor/fontawesome/css/fontawesome-all.min.css";
import "../../vendor/fontawesome-v6/css/custom-icons.min.css";
import "scss/extole-default-styles.scss";

const TEST_CLIENT_IDS = ["1374455248", "655710266"];

const isTestClient = (clientId) => TEST_CLIENT_IDS.includes(clientId);

function App() {
    const events = _.extend({}, Backbone.Events);
    let loginRequired = true;
    let extoleUserRequired = false;
    let chatEnabled = true;

    this.doNotRequireLogin = function () {
        loginRequired = false;
    };

    this.requireExtoleUser = function () {
        extoleUserRequired = true;
    };

    this.disableChat = function () {
        chatEnabled = false;
    };

    this.onStart = function (callback) {
        const fn = () => {
            $(() => {
                pollInitializing();
                callback();
            });
        };
        events.on("start", fn);
    };
    const initializeFoundation = () => {
        $(document).foundation("reflow");
    };

    const pollInitializing = () => {
        let timeInMilliseconds = 0;
        const interval = setInterval(() => {
            timeInMilliseconds += 200;
            initializeFoundation();
            if (timeInMilliseconds >= 10000) {
                clearInterval(interval);
            }
        }, 200);
    };

    this.getSession = function () {
        return Session.getInstance();
    };

    this.start = function () {
        Session.getInstance().start((error) => {
            const clientId = Session.getInstance().getClientId();
            const isUserSupportUser = Session.getInstance().isUserSupportUser();
            const isUserSupportAllowedPage = this.checkUserRestrictness();
            if (error && loginRequired) {
                renderLoginUI();
                return;
            }
            if (isUserSupportUser && !isUserSupportAllowedPage) {
                renderNotAllowedUI();
                return;
            }
            if (extoleUserRequired && !Session.getInstance().isExtoleUser()) {
                window.location = "/";
                return;
            }
            Session.getInstance().onChange(() => {
                setTimeout(reloadPage, 1);
            });
            if (Session.getInstance().isSuperUser()) {
                const uri = new Uri(window.location);
                uri.replaceQueryParam("client_id", clientId);
                window.history.replaceState({}, null, uri);
            }
            removeCloak();
            bootstrapNotifications();
            if (
                featureFlagService.isOn(featureFlagService.FLAGS.CHAT_BOT) &&
                chatEnabled
            ) {
                chatBot.bootstrap();
                setTimeout(() => {
                    initChatBotCloseButton();
                }, 1000);
            }

            if (
                environment.isProduction() &&
                !isLoginPage() &&
                !isTestClient(clientId)
            ) {
                pendo.bootstrap();
                faro.bootstrap();
            }
            extoleInvite.bootstrap();
            addEventListeners();
            events.trigger("start");
        });
    };

    this.startThirdParty = function (accessToken) {
        Session.getInstance(accessToken);
        removeCloak();
        bootstrapNotifications();
        events.trigger("start");
    };

    this.getEnvironment = function (host) {
        host = host || location.hostname;
        const hostMatch = host.match(/-?(lo|nt|qa)\.extole\.com$/);
        if (!hostMatch) {
            return "pr";
        }
        return hostMatch[1];
    };

    this.checkUserRestrictness = function () {
        const path = document.location.pathname;
        const userSupportAllowedPages = [
            "/profiles",
            "/events",
            "/rewards\\/view",
            "/create-event",
            "/support",
            "/access-denied",
            "/zendesk-login",
            "/notifications",
            "/$",
        ];
        const regexPatterns = userSupportAllowedPages.map((page) => {
            return new RegExp(`^${page}`);
        });
        return regexPatterns.some((pattern) => {
            return pattern.test(path);
        });
    };
}

export default new App();

function addEventListeners() {
    window.onhashchange = function () {
        $(".action-view:visible .button--back").click();
    };
}

function renderLoginUI() {
    localStorage.setItem("previousUrl", window.location);
    window.location = "/login";
}

function renderNotAllowedUI() {
    if (window.location.pathname === "/access-denied") {
        return;
    }
    window.location = "/access-denied";
}

function reloadPage() {
    if (isDataInUrl()) {
        window.location.href = "/";
    } else {
        const uri = new Uri(window.location.href);
        uri.deleteQueryParam("client_id");
        window.location.href = uri.toString();
    }
}

function isDataInUrl() {
    const url = window.location.href;
    const path = window.location.pathname;
    return _.contains(url, "#") || /(\d{5,}|^\/program\/)/i.test(path);
}

function removeCloak() {
    $(".js-cloak").removeClass("js-cloak");
}

function bootstrapNotifications() {
    notificationService.start();
}

function isLoginPage() {
    return window.location.pathname === "/login";
}
