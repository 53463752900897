import { CampaignStep } from "./CampaignStep";
import { createCampaignControllerActions } from "./factory/campaignControllerActionFactory";
import { createCampaignControllerTriggers } from "./factory/campaignControllerTriggerFactory";
export const isCampaignController = (step) => {
    return step.type === "CONTROLLER";
};
export class CampaignController extends CampaignStep {
    constructor(response, context) {
        super(response, context);
        this.id = response.id;
        this.name = response.name;
        this.scope = response.scope;
        this.enabled_on_states = response.enabled_on_states;
        this.selectors = response.selectors;
        this.triggers = createCampaignControllerTriggers(response.triggers, {
            campaignId: context.campaignId,
            stepId: response.id,
        });
        this.actions = createCampaignControllerActions(response.actions, {
            campaignId: context.campaignId,
            stepId: response.id,
        });
        this.aliases = response.aliases;
        this.data = response.data;
        this.journey_names = response.journey_names;
    }
    getUniquePartnerEventKeyNames() {
        return this.data
            .filter((data) => data.key_type === "UNIQUE_PARTNER_EVENT_KEY")
            .map((data) => data.name);
    }
    getDataNames() {
        return this.data.map((data) => data.name);
    }
}
