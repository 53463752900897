import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import template from "src/shared/ui-components/forms/inputs/tokenized-list/tokenized-list-template.html?raw";
import "tokenize";

const TokenizedListInputView = Backbone.View.extend({
    initialize(attributes) {
        this.render();
    },

    template: _.template(template),

    events: {},

    render() {
        const values = getValues(this.$el);
        this.$el.html(this.template(getTemplateData(values)));

        const isDisabled = checkIfDisabled(this.$el);
        this.$("select").prop("disabled", isDisabled);

        this.$("select").tokenize({
            onAddToken: this.updateFormViewAttributes.bind(this),
            onRemoveToken: this.updateFormViewAttributes.bind(this),
        });
    },

    updateFormViewAttributes() {
        const values = getAllTokenValues(this.$el);
        this.$el.attr("data-value-json", JSON.stringify(values));
        this.trigger("update");
    },
});

function getValues($element) {
    try {
        return JSON.parse($element.attr("data-value-json"));
    } catch (e) {
        return [];
    }
}

function checkIfDisabled($element) {
    return $element.attr("isDisabled") === "true";
}

function getAllTokenValues($root) {
    const values = $root
        .find(".Token")
        .map(function () {
            return $(this).data("value");
        })
        .get();
    return values;
}

function getTemplateData(values) {
    return {
        values,
    };
}

TokenizedListInputView.create = function ($root) {
    return new TokenizedListInputView({
        el: $root,
    });
};

export default TokenizedListInputView;
