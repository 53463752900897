export class CampaignControllerTrigger {
    constructor(response, context) {
        this.trigger_id = response.trigger_id;
        this.trigger_type = response.trigger_type;
        this.trigger_phase = response.trigger_phase;
        this.trigger_name = response.trigger_name;
        this.trigger_description = response.trigger_description;
        this.enabled = response.enabled;
        this.negated = response.negated;
        this.component_ids = response.component_ids;
        this.context = context;
    }
}
