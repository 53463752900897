var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import _ from "underscore";
import CampaignSummaryEndpoints from "src/shared/api/campaign/CampaignSummaryEndpoints";
import { reactive } from "vue";
import Campaign from "./model/Campaign";
import CampaignEndpoints from "src/shared/api/campaign/CampaignEndpoints";
import { campaignModuleEventBus, EVENT } from "../modules/campaign/event-bus";
import Component from "../component/model/Component";
import { campaignArchiveEvent, campaignChangeEvent, } from "../event-bus/campaign";
import { toPromise } from "src/shared/api/promise";
import CampaignMigrationEndpoints from "src/shared/api/campaign/migration/CampaignMigrationEndpoints";
import { createDurationMeasurement } from "src/shared/measurement";
import { getCampaignOperationQueue } from "../control-flow/campaign-operation-queue";
const DEFAULT_VERSION = "LATEST";
const getComponentsById = (components) => _.reduce(components, (list, component) => (Object.assign(Object.assign({}, list), { [component.id]: new Component(component) })), {});
export const useCampaignStore = defineStore("campaignStore", () => {
    const state = reactive({
        campaigns: {},
        components: {},
        builtCampaigns: {},
        builtComponents: {},
    });
    const setBuiltCampaigns = (campaignResponses, version = DEFAULT_VERSION, isDeep = false) => {
        _.each(campaignResponses, (response) => {
            const campaign = new Campaign(response);
            const { campaign_id } = campaign;
            state.builtCampaigns[version] = Object.assign(Object.assign({}, (state.builtCampaigns[version] || {})), { [campaign_id]: campaign });
            if (isDeep) {
                state.builtComponents[version] = Object.assign(Object.assign({}, (state.builtComponents[version] || {})), { [campaign_id]: getComponentsById(response.components) });
            }
        });
    };
    const deepSetBuiltCampaigns = (deepCampaignResponses, version = DEFAULT_VERSION) => {
        setBuiltCampaigns(deepCampaignResponses, version, true);
        // TODO - set sub-entities
    };
    const setCampaigns = (campaignResponses, version = DEFAULT_VERSION, isDeep = false) => {
        _.each(campaignResponses, (response) => {
            const campaign = new Campaign(response);
            const { campaign_id } = campaign;
            state.campaigns[version] = Object.assign(Object.assign({}, (state.campaigns[version] || {})), { [campaign_id]: campaign });
            if (isDeep) {
                state.components[version] = Object.assign(Object.assign({}, (state.components[version] || {})), { [campaign_id]: getComponentsById(response.components) });
            }
        });
    };
    const deepSetCampaigns = (deepCampaignResponses, version = DEFAULT_VERSION) => {
        setCampaigns(deepCampaignResponses, version, true);
        // TODO - set sub-entities
    };
    const fetchBuiltCampaign = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        const campaignSummaryResponse = yield CampaignSummaryEndpoints.getCampaignSummary(payload.campaignId);
        setBuiltCampaigns([campaignSummaryResponse]);
    });
    const fetchBuiltCampaigns = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        const campaignSummaryResponses = yield CampaignSummaryEndpoints.getCampaignSummaries(payload);
        setBuiltCampaigns(campaignSummaryResponses, payload === null || payload === void 0 ? void 0 : payload.version);
    });
    const deepFetchBuiltCampaigns = () => __awaiter(void 0, void 0, void 0, function* () {
        const campaignResponses = yield CampaignEndpoints.getBuiltCampaigns();
        deepSetBuiltCampaigns(campaignResponses);
    });
    const deepFetchCampaign = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        const { campaignId, version } = payload;
        const campaignResponse = yield CampaignEndpoints.getCampaign(campaignId, version);
        deepSetCampaigns([campaignResponse], version);
    });
    const fetchCampaign = (campaignId) => __awaiter(void 0, void 0, void 0, function* () {
        const campaignResponse = yield toPromise(CampaignEndpoints.getCampaign(campaignId));
        deepSetCampaigns([campaignResponse], DEFAULT_VERSION);
    });
    const deepFetchCampaigns = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        const campaignResponses = yield CampaignEndpoints.getCampaigns(payload);
        deepSetCampaigns(campaignResponses, payload === null || payload === void 0 ? void 0 : payload.version);
    });
    const getCampaign = (campaignId, version = DEFAULT_VERSION) => {
        if (!campaignId) {
            return undefined;
        }
        return (state.campaigns[version] || {})[campaignId];
    };
    const getBuiltCampaign = (campaignId, version = DEFAULT_VERSION) => {
        return state.builtCampaigns[version][campaignId];
    };
    const listCampaigns = (version = DEFAULT_VERSION) => state.campaigns[version];
    const listBuiltCampaigns = (version = DEFAULT_VERSION) => state.builtCampaigns[version];
    const listComponentsByCampaignId = (campaignId, version = DEFAULT_VERSION) => {
        const components = state.components[version];
        if (components) {
            return _.values(components[campaignId]);
        }
        return undefined;
    };
    const listBuiltComponentsByCampaignId = (campaignId, version = DEFAULT_VERSION) => {
        const components = state.builtComponents[version];
        if (components) {
            return _.values(components[campaignId]);
        }
        return undefined;
    };
    const updateCampaign = (campaignId, payload) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            getCampaignOperationQueue(campaignId).push((done) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const campaignResponse = yield toPromise(CampaignEndpoints.editCampaign(campaignId, payload));
                    deepSetCampaigns([campaignResponse]);
                    campaignModuleEventBus.campaignInvalidated(campaignId);
                    campaignChangeEvent.emit({ campaignId });
                    resolve();
                }
                catch (error) {
                    reject(error);
                }
                finally {
                    done();
                }
            }));
        });
    });
    const publishDraft = (campaignId, parameters) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const measurement = createDurationMeasurement("publish_campaign", {
                campaignId,
            });
            CampaignEndpoints.publish(campaignId, parameters)
                .done(() => {
                measurement.send();
                campaignChangeEvent.emit({ campaignId });
                // DEPRECATED - remove when creative variables are gone
                campaignModuleEventBus.publish(EVENT.CAMPAIGN_DRAFT_PUBLISHED, {
                    campaignId,
                });
                resolve();
            })
                .fail(reject);
        });
    });
    const discardDraft = (campaignId) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            CampaignEndpoints.discard(campaignId)
                .done(() => {
                campaignChangeEvent.emit({ campaignId });
                // DEPRECATED - remove when creative variables are gone
                campaignModuleEventBus.publish(EVENT.CAMPAIGN_DRAFT_PUBLISHED, {
                    campaignId,
                });
                resolve();
            })
                .fail(reject);
        });
    });
    const duplicateCampaign = (campaignId, payload) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(CampaignEndpoints.duplicate(campaignId, payload));
        const duplicatedCampaignId = response.campaign_id;
        campaignChangeEvent.emit({ campaignId: duplicatedCampaignId });
        campaignModuleEventBus.campaignInvalidated(duplicatedCampaignId);
        return {
            campaignId: duplicatedCampaignId,
        };
    });
    const archiveCampaign = (campaignId) => __awaiter(void 0, void 0, void 0, function* () {
        yield toPromise(CampaignEndpoints.archive(campaignId));
        campaignArchiveEvent.emit({ campaignId });
    });
    const unarchiveCampaign = (campaignId) => {
        return new Promise((resolve, reject) => {
            CampaignEndpoints.unarchive(campaignId)
                .done((response) => {
                campaignModuleEventBus.campaignsInvalidated();
                resolve(response);
            })
                .fail(reject);
        });
    };
    const lockCampaign = (campaignId) => {
        return new Promise((resolve, reject) => {
            CampaignEndpoints.lock(campaignId, {
                lock_types: ["DUPLICATE", "EDIT"],
            })
                .done((response) => {
                campaignModuleEventBus.campaignInvalidated(campaignId);
                resolve(response);
            })
                .fail(reject);
        });
    };
    const unlockCampaign = (campaignId) => {
        return new Promise((resolve, reject) => {
            CampaignEndpoints.unlock(campaignId, {
                lock_types: ["DUPLICATE", "EDIT"],
            })
                .done((response) => {
                campaignModuleEventBus.campaignInvalidated(campaignId);
                resolve(response);
            })
                .fail(reject);
        });
    };
    const pauseCampaign = (campaignId) => {
        return new Promise((resolve, reject) => {
            CampaignEndpoints.pause(campaignId)
                .done((response) => {
                campaignModuleEventBus.campaignInvalidated(campaignId);
                resolve(response);
            })
                .fail(reject);
        });
    };
    const endCampaign = (campaignId) => {
        return new Promise((resolve, reject) => {
            CampaignEndpoints.end(campaignId)
                .done((response) => {
                campaignModuleEventBus.campaignInvalidated(campaignId);
                resolve(response);
            })
                .fail(reject);
        });
    };
    const stopCampaign = (campaignId) => {
        return new Promise((resolve, reject) => {
            CampaignEndpoints.stop(campaignId)
                .done((response) => {
                campaignModuleEventBus.campaignInvalidated(campaignId);
                resolve(response);
            })
                .fail(reject);
        });
    };
    const launchTest = (campaignId) => {
        return new Promise((resolve, reject) => {
            CampaignEndpoints.launchTest(campaignId)
                .done((response) => {
                campaignModuleEventBus.campaignInvalidated(campaignId);
                resolve(response);
            })
                .fail(reject);
        });
    };
    const publishAndlaunchTest = (campaignId, parameters = {
        message: null,
        launch: null,
    }) => __awaiter(void 0, void 0, void 0, function* () {
        yield publishDraft(campaignId, parameters);
        yield launchTest(campaignId);
    });
    const launchBurst = (campaignId) => {
        return new Promise((resolve, reject) => {
            CampaignEndpoints.launchBurst(campaignId)
                .done((response) => {
                campaignModuleEventBus.campaignsInvalidated();
                resolve(response);
            })
                .fail(reject);
        });
    };
    const publishAndlaunchBurst = (campaignId, parameters = {
        message: null,
        launch: null,
    }) => __awaiter(void 0, void 0, void 0, function* () {
        yield publishDraft(campaignId, parameters);
        yield launchBurst(campaignId);
    });
    const scheduleCampaign = (campaignId, scheduleData) => {
        return new Promise((resolve, reject) => {
            CampaignEndpoints.schedule(campaignId, scheduleData)
                .done((response) => {
                campaignModuleEventBus.campaignInvalidated(campaignId);
                resolve(response);
            })
                .fail(reject);
        });
    };
    const scheduledCampaignPause = (campaignId, version = DEFAULT_VERSION, data) => {
        return new Promise((resolve, reject) => {
            CampaignEndpoints.scheduledPause(campaignId, version, data)
                .done((response) => {
                campaignModuleEventBus.campaignInvalidated(campaignId);
                resolve(response);
            })
                .fail(reject);
        });
    };
    const archiveMultiple = (campaignIds) => {
        return new Promise((resolve) => {
            const promises = _.map(campaignIds, (id) => {
                return CampaignEndpoints.archive(id);
            });
            return Promise.allSettled(promises).then((response) => {
                campaignModuleEventBus.campaignsInvalidated();
                resolve(response);
            });
        });
    };
    const unarchiveMultiple = (campaignIds) => {
        return new Promise((resolve) => {
            const promises = _.map(campaignIds, (id) => {
                return CampaignEndpoints.unarchive(id);
            });
            return Promise.allSettled(promises).then((response) => {
                campaignModuleEventBus.campaignsInvalidated();
                resolve(response);
            });
        });
    };
    const lockMultiple = (campaignIds) => {
        return new Promise((resolve) => {
            const promises = _.map(campaignIds, (id) => {
                return CampaignEndpoints.lock(id, {
                    lock_types: ["DUPLICATE", "EDIT"],
                });
            });
            return Promise.allSettled(promises).then((response) => {
                campaignModuleEventBus.campaignsInvalidated();
                resolve(response);
            });
        });
    };
    const unlockMultiple = (campaignIds) => {
        return new Promise((resolve) => {
            const promises = _.map(campaignIds, (id) => {
                return CampaignEndpoints.unlock(id, {
                    lock_types: ["DUPLICATE", "EDIT"],
                });
            });
            return Promise.allSettled(promises).then((response) => {
                campaignModuleEventBus.campaignsInvalidated();
                resolve(response);
            });
        });
    };
    const migrateCreativesV7ToV8 = (campaignId, source) => __awaiter(void 0, void 0, void 0, function* () {
        yield toPromise(CampaignMigrationEndpoints.migrateCreativesV7ToV8(campaignId, source));
        campaignModuleEventBus.campaignInvalidated(campaignId);
        campaignChangeEvent.emit({ campaignId });
    });
    const makeLatestDraft = (campaignId, version) => __awaiter(void 0, void 0, void 0, function* () {
        yield toPromise(CampaignEndpoints.makeLatestDraft(campaignId, version));
        campaignChangeEvent.emit({ campaignId });
    });
    const migrateGlobalCampaign = (campaignId) => __awaiter(void 0, void 0, void 0, function* () {
        yield toPromise(CampaignMigrationEndpoints.globalCampaignMigration(campaignId));
    });
    campaignChangeEvent.on((event) => {
        fetchCampaign(event.campaignId);
    });
    return {
        fetchCampaign,
        publishDraft,
        discardDraft,
        updateCampaign,
        duplicateCampaign,
        archiveCampaign,
        unarchiveCampaign,
        lockCampaign,
        unlockCampaign,
        pauseCampaign,
        stopCampaign,
        endCampaign,
        launchBurst,
        launchTest,
        publishAndlaunchBurst,
        publishAndlaunchTest,
        scheduleCampaign,
        archiveMultiple,
        unarchiveMultiple,
        lockMultiple,
        unlockMultiple,
        migrateCreativesV7ToV8,
        scheduledCampaignPause,
        fetchBuiltCampaign,
        fetchBuiltCampaigns,
        deepFetchBuiltCampaigns,
        deepFetchCampaign,
        deepFetchCampaigns,
        getBuiltCampaign,
        getCampaign,
        listCampaigns,
        listBuiltCampaigns,
        listComponentsByCampaignId,
        listBuiltComponentsByCampaignId,
        migrateGlobalCampaign,
        makeLatestDraft,
    };
});
