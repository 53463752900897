import faro from "src/shared/third-party-integrations/faro";
import Session from "Session";
import Strings from "../Strings";
import { getErrorMessage } from "../store/common/Exception";
const measurementContextPrefixGenerators = {
    batch_update_component_variables: (data) => {
        return `component.${data.componentId}`;
    },
    campaign_edit_v1_save_variables: (data) => {
        return `campaign.${data.campaignId}`;
    },
    root_campaign_save_and_publish: (data) => {
        return `campaign.${data.campaignId}`;
    },
    publish_campaign: (data) => {
        return `campaign.${data.campaignId}`;
    },
    load_all_programs: (data) => {
        return `programs.${data.clientId}`;
    },
};
const formatFaroDurationMeasurementType = (type, data) => {
    const clientName = Strings.encodeSnakeCase(Session.getInstance().getClientName());
    const contextPrefix = measurementContextPrefixGenerators[type](data);
    return `${clientName}.${contextPrefix}.${type}_duration`;
};
export const createDurationMeasurement = (type, data) => {
    const now = Date.now();
    const send = () => {
        try {
            const duration = Date.now() - now;
            const faroMeasurementType = formatFaroDurationMeasurementType(type, data);
            faro.pushMeasurement(faroMeasurementType, { duration });
        }
        catch (error) {
            faro.pushError(new Error(getErrorMessage(error)));
        }
    };
    return {
        send,
    };
};
