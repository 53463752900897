import { CampaignStep } from "./CampaignStep";
import { createCampaignControllerActions } from "./factory/campaignControllerActionFactory";
import { createCampaignControllerTriggers } from "./factory/campaignControllerTriggerFactory";
export const isCampaignFrontendController = (step) => {
    return step.type === "FRONTEND_CONTROLLER";
};
export class CampaignFrontendController extends CampaignStep {
    constructor(response, context) {
        super(response, context);
        this.id = response.id;
        this.name = response.name;
        this.type = response.type;
        this.scope = response.scope;
        this.enabled_on_states = response.enabled_on_states;
        this.category = response.category;
        this.triggers = createCampaignControllerTriggers(response.triggers, {
            campaignId: context.campaignId,
            stepId: response.id,
        });
        this.actions = createCampaignControllerActions(response.actions, {
            campaignId: context.campaignId,
            stepId: response.id,
        });
        this.aliases = response.aliases;
        this.journey_names = response.journey_names;
    }
}
