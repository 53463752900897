import faro from "src/shared/third-party-integrations/faro";
import { CampaignControllerTriggerGeneric } from "../CampaignControllerTriggerGeneric";
import { CampaignControllerTriggerEvent } from "../CampaignControllerTriggerEvent";
const isCampaignControllerTriggerEventResponse = (response) => {
    return response.trigger_type === "EVENT";
};
const subclasses = {
    SHARE: CampaignControllerTriggerGeneric,
    EVENT: CampaignControllerTriggerEvent,
    SCORE: CampaignControllerTriggerGeneric,
    ZONE_STATE: CampaignControllerTriggerGeneric,
    REFERRED_BY_EVENT: CampaignControllerTriggerGeneric,
    LEGACY_QUALITY: CampaignControllerTriggerGeneric,
    EXPRESSION: CampaignControllerTriggerGeneric,
    ACCESS: CampaignControllerTriggerGeneric,
    DATA_INTELLIGENCE_EVENT: CampaignControllerTriggerGeneric,
    HAS_PRIOR_STEP: CampaignControllerTriggerGeneric,
    MAXMIND: CampaignControllerTriggerGeneric,
    REWARD_EVENT: CampaignControllerTriggerGeneric,
    SEND_REWARD_EVENT: CampaignControllerTriggerGeneric,
    AUDIENCE_MEMBERSHIP: CampaignControllerTriggerGeneric,
    AUDIENCE_MEMBERSHIP_EVENT: CampaignControllerTriggerGeneric,
    HAS_PRIOR_REWARD: CampaignControllerTriggerGeneric,
    HAS_IDENTITY: CampaignControllerTriggerGeneric,
    CLIENT_DOMAIN: CampaignControllerTriggerGeneric,
    LEGACY_LABEL_TARGETING: CampaignControllerTriggerGeneric,
};
function assertNever(x) {
    throw new Error("Unexpected object: " + x);
}
const createInstance = (type, response, context) => {
    const SubClass = subclasses[type];
    return new SubClass(response, context);
};
export const createCampaignControllerTriggers = (responses, context) => {
    const steps = [];
    responses.forEach((response) => {
        switch (response.trigger_type) {
            case "SHARE":
            case "EVENT":
                if (isCampaignControllerTriggerEventResponse(response)) {
                    steps.push(createInstance(response.trigger_type, response, context));
                }
                break;
            case "SCORE":
            case "ZONE_STATE":
            case "REFERRED_BY_EVENT":
            case "LEGACY_QUALITY":
            case "EXPRESSION":
            case "ACCESS":
            case "DATA_INTELLIGENCE_EVENT":
            case "HAS_PRIOR_STEP":
            case "MAXMIND":
            case "REWARD_EVENT":
            case "SEND_REWARD_EVENT":
            case "AUDIENCE_MEMBERSHIP":
            case "AUDIENCE_MEMBERSHIP_EVENT":
            case "HAS_PRIOR_REWARD":
            case "HAS_IDENTITY":
            case "CLIENT_DOMAIN":
            case "LEGACY_LABEL_TARGETING":
                steps.push(createInstance(response.trigger_type, response, context));
                break;
            default:
                assertNever(response.trigger_type);
                faro.pushError(new Error(`Unknown type, ${response.trigger_type}`));
        }
    });
    return steps;
};
