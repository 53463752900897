import faro from "src/shared/third-party-integrations/faro";
import { CampaignControllerActionDisplay } from "../CampaignControllerActionDisplay";
import { CampaignControllerActionGeneric } from "../CampaignControllerActionGeneric";
import { CampaignControllerActionCreative } from "../CampaignControllerActionCreative";
import { CampaignControllerActionEarnReward } from "../CampaignControllerActionEarnReward";
export const isCampaignControllerTriggerActionCreativeResponse = (response) => response.action_type === "CREATIVE";
const subclasses = {
    CREATIVE: CampaignControllerActionCreative,
    DISPLAY: CampaignControllerActionDisplay,
    EMAIL: CampaignControllerActionGeneric,
    SCHEDULE: CampaignControllerActionGeneric,
    SIGNAL: CampaignControllerActionGeneric,
    SIGNAL_V1: CampaignControllerActionGeneric,
    INCENTIVIZE: CampaignControllerActionGeneric,
    SHARE_EVENT: CampaignControllerActionGeneric,
    FIRE_AS_PERSON: CampaignControllerActionGeneric,
    DATA_INTELLIGENCE: CampaignControllerActionGeneric,
    APPROVE: CampaignControllerActionGeneric,
    DECLINE: CampaignControllerActionGeneric,
    EARN_REWARD: CampaignControllerActionEarnReward,
    FULFILL_REWARD: CampaignControllerActionGeneric,
    REDEEM_REWARD: CampaignControllerActionGeneric,
    CANCEL_REWARD: CampaignControllerActionGeneric,
    REVOKE_REWARD: CampaignControllerActionGeneric,
    STEP_SIGNAL: CampaignControllerActionGeneric,
    WEBHOOK: CampaignControllerActionGeneric,
    EXPRESSION: CampaignControllerActionGeneric,
    INCENTIVIZE_STATUS_UPDATE: CampaignControllerActionGeneric,
    CREATE_MEMBERSHIP: CampaignControllerActionGeneric,
    REMOVE_MEMBERSHIP: CampaignControllerActionGeneric,
};
function assertNever(x) {
    throw new Error("Unexpected object: " + x);
}
const createInstance = (type, response, context) => {
    const SubClass = subclasses[type];
    return new SubClass(response, context);
};
export const createCampaignControllerActions = (responses, context) => {
    const steps = [];
    responses.forEach((response) => {
        switch (response.action_type) {
            case "CREATIVE":
                if (isCampaignControllerTriggerActionCreativeResponse(response)) {
                    steps.push(createInstance(response.action_type, response, context));
                }
                break;
            case "DISPLAY":
            case "EMAIL":
            case "SCHEDULE":
            case "SIGNAL":
            case "SIGNAL_V1":
            case "INCENTIVIZE":
            case "SHARE_EVENT":
            case "FIRE_AS_PERSON":
            case "DATA_INTELLIGENCE":
            case "APPROVE":
            case "DECLINE":
            case "EARN_REWARD":
            case "FULFILL_REWARD":
            case "REDEEM_REWARD":
            case "CANCEL_REWARD":
            case "REVOKE_REWARD":
            case "STEP_SIGNAL":
            case "WEBHOOK":
            case "EXPRESSION":
            case "INCENTIVIZE_STATUS_UPDATE":
            case "CREATE_MEMBERSHIP":
            case "REMOVE_MEMBERSHIP":
                steps.push(createInstance(response.action_type, response, context));
                break;
            default:
                assertNever(response.action_type);
                faro.pushError(new Error(`Unknown type, ${response.action_type}`));
        }
    });
    return steps;
};
