import $ from "jquery";
import Uri from "jsuri";
const baseUrl = "api:///v2/campaigns";
const migrateCreativesV7ToV8 = (campaignId, rootComponentSource) => {
    const uri = new Uri(`${baseUrl}/${campaignId}/migrate-creative-v7-to-v8`);
    if (rootComponentSource) {
        uri.addQueryParam("root_component_source", rootComponentSource);
    }
    return $.ajax({
        method: "POST",
        url: uri.toString(),
    });
};
const globalCampaignMigration = (campaignId) => {
    const uri = new Uri(`${baseUrl}/global/pull-client-variables`);
    uri.addQueryParam("source_campaign_id", campaignId);
    return $.ajax({
        method: "POST",
        url: uri.toString(),
    });
};
export default {
    migrateCreativesV7ToV8,
    globalCampaignMigration,
};
